
$palette-flipdish-yellow-dark:#FF9300;
$palette-flipdish-yellow-light:#FFBA00;

$primary-top-bottom:linear-gradient($palette-flipdish-yellow-dark, $palette-flipdish-yellow-light);


$font-family-sans-serif:'PT Sans Narrow', sans-serif;

$palette-flipdish-darkblue:#000E37;
$palette-flipdish-mediumblue:#0076BE;
$palette-flipdish-lightblue:#1192D4;
$palette-flipdish-grey:#575556;
$palette-flipdish-black:#000000;
$palette-flipdish-white:#FFFFFF;
$palette-flipdish-highlight:#46CAA8;


$font-size:0.875rem;
$font-weight-normal:300;



$body-bg:#F3F4F4;
$body-color:#FFFFFF;

$primary:$palette-flipdish-yellow-light;
$primary-dark:$palette-flipdish-yellow-dark;

$color-in-primary:#1E1E1E;

$secondary:#C6C6C6;

$link-decoration:none;

$btn-focus-box-shadow:red;
$btn-active-box-shadow:green;

$font-weight-base:200;

@import '~bootstrap/scss/bootstrap.scss';

// 4. Include any optional Bootstrap CSS as needed
@import "~bootstrap/scss/utilities";

$border-radius:2px;

$border-style:0.3rem #FFBA00 dashed;

$placeholder-color:#999999;




html {
    @media (max-width:499px) {
        font-size:70%;
    }
    @media (min-width:500px) {
        font-size:75%;
    }
    @media (min-width:800px) {
        font-size:85%;
    }
    @media (min-width:1150px) {
        font-size:92.5%;
    }
    @media (min-width:1300px) {
        font-size:100%;
    }
    @media (min-width:1520px) {
        font-size:110%;
    }
    @media (min-width:1700px) {
        font-size:120%;
    }
}

body {
    min-width:1200px; min-height:100vh; font-size:$font-size; background-image:url(imgs/background.jpeg); background-size:cover; background-position:center top; background-attachment:fixed;

    #root,
    #wrapper { width:100%; }

    a,
    a:hover,
    a:focus,
    a:visited,
    a:active,
    button,
    button:hover,
    button:focus,
    button:visited,
    button:active,
    input,
    input:hover,
    input:focus,
    input:visited,
    input:active 
    textarea,
    textarea:hover,
    textarea:focus,
    textarea:visited,
    textarea:active { outline:none !important; text-decoration:none !important; box-shadow:none !important; }
    input { background-color:#FFFFFF !important; }

    

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 { margin-bottom:0; }
    h4 { font-size:1.2rem; }
    h5 { font-size:1rem; }
    strong { font-weight:400; }
    small,
    .small { font-size:80%; }
    .btn:not(.btn-link) { color:#FFFFFF; border-radius:$border-radius; }
    .btn-primary {background-color:$primary; background-image:$primary-top-bottom; }

    .title { font-family:'Water Brush', cursive; }

    .text-placeholder { color:$placeholder-color; }

    .flex-column { flex-direction:column; }

    .container-image {  }

    .name { font-size:1.2rem; }
    .price { font-size:1rem; }

    .btn-dropdown-item {
        border-radius:50%; color:#FFFFFF; background-image:$primary-top-bottom; width:1rem; height:1rem; padding:0px; display:flex; align-items:center; justify-content:center; background-color:$primary-dark;
        &:hover { background-image:none; color:#FFFFFF !important; }
        p { line-height:1rem; vertical-align:middle; text-align:center; }
    }

    #wrapper {
        min-height:100vh; position:relative; background-color:rgba(0,0,0,0); -webkit-transition:background-color 1000ms linear; -ms-transition:background-color 1000ms linear; transition:background-color 1000ms linear;
        #topnavbar {
            width:100%; height:3.75rem; position:fixed; top:0px; left:0px; z-index:999;
            &.scroll { background-color:rgba(0,0,0,0.7); -webkit-transition:background-color 1000ms linear; -ms-transition:background-color 1000ms linear; transition:background-color 1000ms linear; }
            .navbar {
                width:100%; height:100%; padding:0.5rem 2rem; position:relative;
                .navbar-brand {
                    height:2rem; padding:0px;
                    img { max-height:2rem; }
                }
                #navbar-menu {
                    a {
                        color:$body-color;
                        &.active,
                        &:hover { color:$primary; }
                    }
                    #btn-login { color:$color-in-primary; }
                    #btn-login,
                    #btn-logout {
                        border-radius:$border-radius; font-weight:400; padding:0.425rem 0.8rem; font-size:$font-size;
                        &:focus { box-shadow:none !important; }
                    }
                    #btn-logout {
                        color:$palette-flipdish-white;
                        &:hover { color:$primary; }
                    }
                    .container-drowdown-bill {
                        #dropdown-bill {
                            background-color:#FFFFFF; color:#333333 !important; position:relative; width:8rem; padding-right:1.8rem;
                            p { width:100%; display:inline-flex; justify-content:space-between; }
                            &:before { content:''; width:100%; height:calc(100% + 0.2rem); position:absolute; z-index:-1; left:0px; top:-0.1rem; border-top:0.15rem dashed #FFFFFF; border-bottom:0.15rem dashed #FFFFFF; }
                        }
                        .dropdown-menu {
                            width:18rem; border-radius:0px; margin-top:1rem; color:#333333; box-shadow:0px 3px 12px 8px rgba(0,0,0,0.9);
                            a {
                                color:#333333;
                                &:hover { color:$primary; }
                            }
                            &:before { content:''; width:100%; height:calc(100% + 0.3rem); position:absolute; z-index:-1; left:0px; top:-0.15rem; border-top:0.3rem dashed #FFFFFF; border-bottom:0.3rem dashed #FFFFFF; }
                            div.dropdown-item { flex-direction:column; }
                            .dropdown-item {
                                display:flex;
                                .receipt-item-container {
                                    display:flex; align-items:center; justify-content:space-between;
                                    .receipt-item { display:inline-flex; align-items:center; }
                                }
                                .receipt-itemSet-container {
                                    display:flex; flex-direction:column;
                                    & > div {
                                        display:flex; align-items:center; justify-content:space-between;
                                        .receipt-itemSet { padding-left:0.5rem; }
                                    }
                                }
                                p:not(.delete-icon) { width:100%; display:flex; align-items:center; justify-content:space-between; padding-right:1rem; }
                                &:hover { background-color:transparent !important; }
                                &.logo-receipt {
                                    text-align:center;
                                    img { width:60%; margin:auto; }
                                }
                                &.total-bill { padding-right:3rem; font-weight:700; justify-content:space-between; }
                                label { font-size:0.8rem; margin-left:0.4rem; }
                                .receipt-item {
                                    font-weight:700; display:inline-flex;
                                    .receipt-itemSet { font-weight:300; padding-left:0.5rem; }
                                }
                            }
                            .dropdown-divider { border-top:0.2rem #333333 dotted; background-color:transparent !important; opacity:1; margin:0.7rem; }
                        }
                    }
                }
            }
        }

        &.developer { 
            main { padding-top:3.75rem !important; }
        }

        main {
            min-height:100vh; padding-top:12rem; padding-bottom:59px; position:relative;            

            .menu-title {
                height:10rem; position:absolute; top:1rem; left:50%; transform:translateX(-50%); display:flex; flex-direction:column;
                h1 { font-size:6rem; background:linear-gradient($palette-flipdish-yellow-dark, $palette-flipdish-yellow-light); -webkit-background-clip:text; -webkit-text-fill-color:transparent; display:inline-block; z-index:2; }
                p { font-size:4rem; line-height:1.2; text-align:center; margin-top:-11%; display:inline-block; z-index:1; border-bottom:0.3rem #FFBA00 solid; }
            }

            .widget-container {
                padding:2rem 1rem 0rem 1rem;
                .widget {
                    min-height:100%;
                    .widget-header { padding:1rem 2rem; }
                    .widget-body {
                        padding:1rem 2rem 3rem 2rem;
                        & > .container-fluid { padding:0px; }
                    }

                    
                }
            }

            #menu-feature {
                display:flex; justify-content:center; flex-wrap:wrap;
                & > section {
                    max-width:33%; display:inline-block; flex:1 0 33%; padding:9.25rem 2.5rem 2.5rem 2.5rem; position:relative;
                    & > header {
                        position:absolute; left:50%; transform:translateX(-50%); top:1rem;
                        & > div {
                            position:relative; z-index:1;
                            .container-image {
                                width:10rem; height:10rem; padding:0.6rem; border-radius:50%; border:$border-style;
                                .sectionImage { width:8.2rem; height:8.2rem; border-radius:50%; background-size:cover; background-repeat:no-repeat; background-position:center center; }
                            }
                            h2 { width:120%; height:2.6rem; position:absolute; left:50%; margin-left:-60%; bottom:0.55rem; padding-top:0.15rem; padding-bottom:0.15rem; background-color:$primary; background-image:$primary-top-bottom; font-size:2rem; font-weight:700; font-family:'Dancing Script', cursive; text-align:center; color:$color-in-primary; box-shadow:0px 4px 4px rgba(0,0,0,0.5);
                                &:before { content:''; position:absolute; z-index:-1; right:95%; bottom:-0.25rem; border-top:1.3rem solid $primary-dark; border-bottom:1.3rem solid $primary-dark; border-left:1.3rem solid transparent; border-right:1.3rem solid $primary-dark; }
                                &:after { content:''; position:absolute; z-index:-1; left:95%; bottom:-0.25rem; border-top:1.3rem solid $primary-dark; border-bottom:1.3rem solid $primary-dark; border-right:1.3rem solid transparent; border-left:1.3rem solid $primary-dark; }
                            }
                        }
                    }
                    & > div{
                        width:100%; height:100%; display:flex; flex-direction:column; row-gap:1.5rem; border:0.3rem double $primary; padding:3.5rem 1.5rem 2.5rem 1.5rem; min-height:20rem;
                        .container-section-item {
                            display:flex; flex-direction:column;
                            .container-section-item-header {
                                display:flex; align-items:center; justify-content:start; column-gap:1.5rem;
                                &:last-child { margin-bottom:0px; }
                                .sectionItemImage { border-radius:50%; width:5rem; height:5rem; min-width:5rem; min-height:5rem; background-size:cover; background-repeat:no-repeat; background-position:center center; box-shadow:0px 0px 0px 0.15rem $primary; z-index:2; }
                                .sectionItemData {
                                    display:flex; flex-direction:column; flex:1 1;
                                    a,
                                    h3 { display:flex; align-items:end; justify-content:space-between; }
                                }

                            }
                            &.hasSet {
                                padding-bottom:20px; border-bottom:0.25rem $primary dashed;
                                &:last-child { border-bottom:none !important; }
                                .container-section-item-header {
                                    margin-bottom:0.5rem;
                                    .sectionItemData {
                                        h3 {
                                            .item-name {
                                                width:80%; height:2rem; line-height:2rem; vertical-align:middle; color:$color-in-primary; background-image:$primary-top-bottom; margin-left:-2rem; padding-left:2rem; position:relative; box-shadow:0px 2px 3px rgba(0,0,0,0.5);
                                                &:after { content:''; position:absolute; z-index:-1; left:95%; bottom:-0.2rem; border-top:1rem solid $primary-dark; border-bottom:1rem solid $primary-dark; border-right:1rem solid transparent; border-left:1rem solid $primary-dark; }
                                            }
                                        }
                                    }
                                    &.hasNotImage {
                                        .sectionItemData {
                                            h3 {
                                                .item-name { width:calc(80% - 0.7rem); margin-left:0px; }
                                            }
                                        }
                                    }
                                }
                                .item-description { text-align:center; padding:0px 1rem; }
                            }
                            label { color:#FFFFFF; font-style:italic; }
                            .container-section-item-set {
                                .container-section-item-set-setItem {
                                    border-bottom:0.2rem $primary dotted; padding:0.5rem 0px;
                                    &:last-child { border-bottom:none !important; }
                                    a { display:flex; justify-content:space-between; font-size:1.2rem; }
                                }
                            }
                        }
                    }
                    .btn-link { padding-left:0px !important; padding-right:0px !important; }
                }
            }

            #developer-feature {
                #profilephoto { max-width:12rem; padding:0.8rem; border-radius:50%; border:$border-style; }
                #profiledescription { text-align:center; font-style:italic; }
                #contacts-container {
                    display:inline-flex; align-items:start; flex-direction:column; padding-bottom:2rem; border-bottom:$border-style;
                }
            }
        }

        #footer {
            width:100%; height:59px; position:absolute; bottom:0px; padding:20px 2rem 0.5rem 2rem; display:flex; align-items:center; justify-content:space-between; font-size:70%;
            a {
                color:$palette-flipdish-white;
                &:hover { color:$primary; }
            }
        }
    }

    .modal-content {
        border-radius:$border-radius;
        .modal-header {
             display:flex; align-items:center; justify-content:center; box-shadow:0px 0px 9px rgba(122, 122, 122, 0.1); z-index:1;
            .modal-title { font-size:1rem; color:#333333; }
        }
        .modal-body { background-color:$body-bg; padding:2rem 4rem; }
    }

    

}

